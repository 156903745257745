import React, { Component } from "react";
import styled from "styled-components";
import { API_BASE_URL } from "../../config/constants";

class TeamLogo extends Component {
  render() {
    const { teamId, size, className } = this.props;

    return (
      <Image
        className={className || ""}
        src={`${API_BASE_URL}/teams/${teamId}/logo`}
        alt={teamId}
        height={size}
        width={size}
      />
    );
  }
}

const Image = styled.img`
  max-width: ${(props) => props.width}px;
  max-height: ${(props) => props.height}px;

  height: auto;
  width: auto;
`;

export default TeamLogo;
